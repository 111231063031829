import React from "react"
import PropTypes from "prop-types"

const PageAPropos = props => {
  return (
    <div>

    </div>
  )
}

PageAPropos.propTypes = {

}

export default PageAPropos
